<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Groups' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($tc('words.userGroup', 2)) }}
      </span>
    </div>
    <b-overlay
      v-if="loading"
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      style="min-height: 30em;"
    />
    <b-container
      v-else
      fluid
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
          >
            <h1>{{ currentUsergroup.display_name }}</h1>
            <GroupForm
              :group="currentUsergroup"
              @update="updateForm"
            />
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <UsergroupThumbnail
              :display-name="currentUsergroup.display_name"
              :usergroup="currentUsergroup"
              :thumbnail="currentUsergroup.thumbnail"
            />
            <div class="side-footer">
              <b-button
                variant="outline-secondary"
                @click="handleSubmit(updateGroup)"
              >
                {{ $t('buttons.saveChanges') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
      <b-row style="height: auto;">
        <b-col
          cols="11"
          class="users-table"
        >
          <h3 class="line-header">
            <img
              src="@/assets/icons/face_user.svg"
              alt="Icon face user"
            > {{ $t('groupDetail.users.title') }}
          </h3>
          <h5 style="margin-left: 1em;">
            {{ $t('groupDetail.users.label') }} {{ currentUsergroup.display_name }}
          </h5>
          <p style="margin: 0 0 0 1.2em; font-size: 1em;">
            {{ $t('groupDetail.users.help') }}
          </p>
          <form style="margin-top: 0;">
            <div
              class="form-row"
              style="align-items: flex-end; margin-left: -1.2em;"
            >
              <div class="form-group col-4">
                <SearchUsers
                  :reset-after="false"
                  :placeholder="$t('groupDetail.users.placeholder')"
                  :current-users="currentUsergroupMembers"
                  @select="setSelectedUser"
                />
              </div>
              <div
                class="form-group col-3"
                :class="{disabled: !selectedUser.user}"
              >
                <Multiselect
                  v-model="selectedUser.role"
                  :options="usergroupsRoles"
                  track-by="choice"
                  label="label"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  :searchable="false"
                  :placeholder="$t('groupDetail.users.role')"
                  @input="handleBlur"
                />
              </div>
              <div
                class="form-group col-3"
                :class="{disabled: !selectedUser.role}"
              >
                <Multiselect
                  v-model="selectedUser.status"
                  :options="statusOptions"
                  track-by="choice"
                  label="label"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  :searchable="false"
                  :placeholder="$t('groupDetail.users.status')"
                  @input="handleBlur"
                />
              </div>
              <div class="form-group col-2">
                <b-button
                  variant="success"
                  :disabled="!allowAdd"
                  @click.prevent="addUserToGroup"
                >
                  {{ $t('buttons.confirm') }}
                </b-button>
              </div>
            </div>
          </form>
          <h5 style="margin-left: 1em;">
            {{ $t('groupDetail.table.title') }} {{ currentUsergroup.display_name }}
          </h5>
          <SearchablePaginatedTable
            :name="'groupMembersTable'"
            :margin="'1rem 0 1rem 1rem'"
            :loading="loadingMembersTable"
            :searchable="false"
            :route-to="'UserDetail'"
            :fields="membersFields"
            :rows="membersRows"
            :count="currentUsergroupMembersCount"
            :sort-function="GET_USERGROUP_MEMBERS"
            :default-sorting="{
              sortedUp: true,
              sortedDown: false,
              sortedField: membersTableSorted.field
            }"
            :selectable="false"
            @sorted="setMembersTableSorted"
            @change-page="changeMembersTablePage"
            @delete="openMemberRemoveModal"
          />
        </b-col>
      </b-row>

      <b-row style="height: auto;">
        <b-col
          cols="11"
          class="users-table"
        >
          <h3 class="line-header">
            <img
              src="@/assets/icons/face_user.svg"
              alt="Icône sphère"
            > {{ $t('groupDetail.contacts.title') }}
          </h3>
          <b-overlay
            :show="loadingContactsTable"
            :variant="'white'"
            :opacity="0.9"
            :blur="'2px'"
            rounded="sm"
          >
            <button
              v-if="!isCreatingContact"
              class="add-button"
              style="margin-bottom: 1rem;"
              @click.prevent="isCreatingContact = true"
            >
              <b-icon-plus />
              {{ $t('groupDetail.contacts.create') }}
            </button>
            <div
              v-if="isCreatingContact"
              class="add-contact"
            >
              <ValidationObserver v-slot="{ handleSubmit }">
                <DatasetContactForm
                  :current-contacts="contactsList"
                  :contact="newContact"
                  :usergroup-disabled="true"
                  @change="setNewContact"
                  @delete="isCreatingContact = false"
                />
                <div class="contact-form-footer">
                  <b-button
                    variant="primary"
                    @click.prevent="handleSubmit(addContact)"
                  >
                    {{ $t('buttons.add') }}
                  </b-button>
                </div>
              </ValidationObserver>
            </div>
            <SearchablePaginatedTable
              :name="'groupContactsTable'"
              :margin="'1rem 0 1rem 1rem'"
              :loading="loadingContactsTable"
              :searchable="false"
              :fields="contactsFields"
              :rows="contactsRows"
              :sort-function="GET_CONTACTS_LIST"
              :default-sorting="{
                sortedUp: true,
                sortedDown: false,
                sortedField: 'name'
              }"
              :selectable="false"
              :allow-modification="true"
              @change-page="changeContactsTablePage"
              @sorted="setMembersTableSorted"
              @modify="modifyContact"
              @delete="openContactRemoveModal"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
    <UpdateContact
      :open="isUpdatingContact"
      :contacts-list="contactsList"
      :contact="contactToUpdate"
      @update="updateContact"
      @hide="isUpdatingContact = false"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import GroupForm from '@/components/Forms/GroupForm.vue';
import SearchUsers from '@/components/Searchbars/SearchUsers.vue';
import DatasetContactForm from '@/components/Forms/DatasetContactForm.vue';
import UpdateContact from '@/components/Modals/UpdateContact.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import UsergroupThumbnail from '@/components/Usergroup/UsergroupThumbnail.vue';

import { mapState, mapMutations, mapActions } from 'vuex';
import datasetsAPI from '@/api/datasetsAPI.js';
import { ValidationObserver } from 'vee-validate';

import { ErrorService } from '@/services/error-service.js';

export default {
  name: 'GroupDetail',

  components: {
    Multiselect,
    GroupForm,
    SearchUsers,
    DatasetContactForm,
    UpdateContact,
    SearchablePaginatedTable,
    UsergroupThumbnail,
    ValidationObserver
  },

  data() {
    return {
      loading: false,
      loadingMembersTable: false,
      loadingContactsTable: false,

      form: {},

      membersTableSorted: {
        direction: '-',
        field: 'date_joined'
      },
      userToRemove: null,
      selectedUser: {
        user: null,
        role: null,
        status: null
      },
      statusOptions: [
        {
          choice: 0,
          label: this.$t('groupDetail.users.statusOptions.verification')
        },
        {
          choice: 1,
          label: this.$t('groupDetail.users.statusOptions.validation')
        },
        {
          choice: 2,
          label: this.$t('groupDetail.users.statusOptions.active')
        }
      ],
      allowAdd: false,

      isCreatingContact: false,
      newContact: {
        id: null,
        role: null,
        contact: {
          id: null,
          email: null,
          name: '',
          position: null,
          phone_number: null,
          usergroup: null
        }
      },
      isUpdatingContact: false,
      contactToUpdate: null,
      contactToRemove: null
    };
  },

  computed: {
    ...mapState('usergroups', [
      'currentUsergroup',
      'currentUsergroupMembers',
      'currentUsergroupMembersCount',
      'currentUsergroupChildren',
      'usergroupsRoles',
      'contactsList'
    ]),
    ...mapState('table', [
      'currentTables'
    ]),

    membersFields() {
      return [
        {
          key: this.$t('groupDetail.table.fields.lastname'),
          apiName: 'user__last_name',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('groupDetail.table.fields.firstname'),
          apiName: 'user__first_name',
          sortable: true,
          width: '10%',
          centered: false
        },
        {
          key: this.$t('groupDetail.table.fields.id'),
          apiName: 'user__username',
          sortable: true,
          width: '10%',
          centered: false
        },
        {
          key: this.$t('groupDetail.table.fields.role'),
          apiName: 'role',
          sortable: true,
          width: '8%',
          centered: false
        },
        {
          key: this.$t('groupDetail.table.fields.registrationDate'),
          apiName: 'date_joined',
          sortable: true,
          width: '15%',
          centered: true
        },
        {
          key: this.$t('groupDetail.table.fields.loginDate'),
          apiName: 'user__last_login',
          sortable: true,
          width: '15%',
          centered: true
        },
        {
          key: this.$t('groupDetail.table.fields.status'),
          apiName: 'status',
          sortable: true,
          width: '9%',
          centered: true
        },
        {
          key: this.$t('groupDetail.table.fields.admin'),
          apiName: 'is_superuser',
          sortable: false,
          width: '7%',
          centered: true
        },
        {
          key: this.$t('groupDetail.table.fields.delete'),
          sortable: false,
          width: '17%',
          centered: true
        }
      ];
    },
    membersRows() {
      return this.currentUsergroupMembers.map(el => {
        return {
          id: el.user.id,
          [this.$t('groupDetail.table.fields.lastname')]: el.user.last_name,
          [this.$t('groupDetail.table.fields.firstname')]: el.user.first_name,
          Identifiant: el.user.username,
          Rôle:
            this.usergroupsRoles.find(item => item.choice === el.role) ? this.usergroupsRoles.find(item => item.choice === el.role).label : '',
          'Date d\'inscription': new Date(el.date_joined).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }),
          'Dernière connexion': new Date(el.user.last_login).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }),
          Statut: [
            { choice: 0, value: 'secondary' },
            { choice: 1, value: 'warning' },
            { choice: 2, value: 'success' }
          ].find(item => item.choice === el.status).value,
          Admin: el.user.is_superuser ? el.user.is_superuser : false
        };
      });
    },
    contactsFields() {
      return [
        {
          key: this.$t('groupDetail.contacts.fields.name'),
          apiName: 'name',
          sortable: false,
          width: '30',
          centered: false
        },
        {
          key: this.$t('groupDetail.contacts.fields.email'),
          apiName: 'email',
          sortable: false,
          width: '30%',
          centered: false
        },
        {
          key: this.$t('groupDetail.contacts.fields.phone'),
          apiName: 'phone_number',
          sortable: false,
          width: '15%',
          centered: false
        },
        {
          key: this.$t('groupDetail.contacts.fields.position'),
          apiName: 'role',
          sortable: false,
          width: '15',
          centered: false
        },
        {
          key: this.$t('groupDetail.contacts.fields.edit'),
          sortable: false,
          width: '10%',
          centered: true
        },
        {
          key: this.$t('groupDetail.contacts.fields.delete'),
          sortable: false,
          width: '10%',
          centered: true
        }
      ];
    },
    contactsRows() {
      return this.contactsList.map(el => {
        return {
          id: el.id,
          Nom: el.name,
          Email: el.email,
          Téléphone: el.phone_number,
          'Position/service': el.position
        };
      });
    }
  },

  created() {
    this.loading = true;
    if (this.usergroupsRoles.length === 0) {
      this.GET_USERGROUPS_ROLES();
    }
    Promise.all([
      this.GET_USERGROUP_DETAIL(this.$route.params.id),
      this.GET_USERGROUP_MEMBERS({
        id: this.$route.params.id,
        ...this.membersTableSorted,
        page: 1
      }),
    ])
      .then(() => {
        this.GET_CONTACTS_LIST({
          usergroupId: this.currentUsergroup.id
        })
          .then(() => {
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
          });
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
    this.SET_TABLE({
      name: 'groupMembersTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
    this.SET_TABLE({
      name: 'groupContactsTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isUpdatingContact = false;
    });
    this.SET_TABLE({
      name: 'groupMembersTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
    this.SET_TABLE({
      name: 'groupContactsTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUP_DETAIL',
      'GET_USERGROUP_MEMBERS',
      'GET_USERGROUP_CHILDREN',
      'GET_USERGROUPS_GROUPS_LIST',
      'GET_USERGROUPS_ROLES',
      'UPDATE_USERGROUP',
      'GET_CONTACTS_LIST'
    ]),
    ...mapActions('users', [
      'GET_USERS_LIST',
      'ADD_USER_ROLE',
      'REMOVE_USER_ROLE'
    ]),

    updateForm(e) {
      this.form = e;
    },

    setSelectedUser(e) {
      this.selectedUser.user = e;
    },

    addUserToGroup() {
      this.loading = true;
      const data = {
        role: this.selectedUser.role.choice,
        status: this.selectedUser.status.choice,
        user: { id: this.selectedUser.user.id },
      };
      this.ADD_USER_ROLE({
        usergroup: this.currentUsergroup,
        data: data,
        type:'group'
      })
        .then(() => {
          Promise.all([
            this.GET_USERGROUP_DETAIL(this.$route.params.id),
            this.GET_USERGROUP_MEMBERS({
              id: this.$route.params.id,
              ...this.membersTableSorted,
              page: this.currentTables.find(t => t.name === 'groupMembersTable') ?
                this.currentTables.find(t => t.name === 'groupMembersTable').currentPage :
                1
            })
          ]).then(() => {
            this.loading = false;
          });
        });
    },

    handleBlur() {
      if (this.selectedUser.organisation !== null && this.selectedUser.role !== null && this.selectedUser.status !== null) {
        this.allowAdd = true;
      } else {
        this.allowAdd = false;
      }
    },

    updateGroup() {
      this.loading = true;
      this.UPDATE_USERGROUP({
        id: this.currentUsergroup.id,
        data: this.form
      }).then(() => {
        Promise.all([
          this.GET_USERGROUP_DETAIL(this.$route.params.id),
          this.GET_USERGROUPS_GROUPS_LIST({
            direction: null,
            field: null
          }),
        ]).then(() => {
          this.loading = false;
        });
      });
    },

    changeMembersTablePage(e) {
      this.loadingMembersTable = true;
      this.GET_USERGROUP_MEMBERS({
        id: this.$route.params.id,
        ...this.membersTableSorted,
        page: e
      })
        .then(() => {
          this.loadingMembersTable = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingMembersTable = false;
        });
    },

    changeContactsTablePage() {},

    setMembersTableSorted(e) {
      this.membersTableSorted = e;
    },

    openMemberRemoveModal(e) {
      this.userToRemove = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.removeUserFromGroup.title'),
        content: this.$t('modals.removeUserFromGroup.content', {
          user: `${this.userToRemove[this.$t('groupDetail.table.fields.firstname')]} ${this.userToRemove[this.$t('groupDetail.table.fields.lastname')]}`,
          group: this.currentUsergroup.display_name
        }),
        trigger: this.removeUserFromGroup
      });
    },

    removeUserFromGroup() {
      const user = this.currentUsergroupMembers.find(el => el.user.id === this.userToRemove.id);
      this.loading = true;
      this.REMOVE_USER_ROLE({
        usergroup: this.currentUsergroup,
        userId: user.id,
        type: 'group'
      })
        .then(() => {
          Promise.all([
            this.GET_USERGROUP_DETAIL(this.$route.params.id),
            this.GET_USERGROUP_MEMBERS({
              id: this.$route.params.id,
              ...this.membersTableSorted,
              page: this.currentTables.find(t => t.name === 'groupMembersTable') ?
                this.currentTables.find(t => t.name === 'groupMembersTable').currentPage :
                1
            }),
            this.GET_USERS_LIST({
              direction: null,
              field: null
            })
          ])
            .then(() => {
              this.loading = false;
            });
        });
    },

    modifyContact(e) {
      this.contactToUpdate = {
        contact: this.contactsList.find(el => el.id === e.id)
      };
      this.isUpdatingContact = true;
    },

    async updateContact(e) {
      try {
        this.loadingContactsTable = true;
        await datasetsAPI.updateContact(e.id, e);
        this.GET_CONTACTS_LIST({
          usergroupId: this.currentUsergroup.id
        })
          .then(() => {
            this.loadingContactsTable = false;
          })
          .catch(() => {
            this.loadingContactsTable = false;
          });
      } catch (err) {
        this.loadingContactsTable = false;
        ErrorService.onError(err);
        console.error(err);
      }
    },

    setNewContact(e) {
      this.newContact.contact = {
        position: e.contact.position,
        email: e.contact.email,
        name: e.contact.name,
        phone_number: e.contact.phone_number,
        usergroup_id: this.currentUsergroup.id
      };
    },

    async addContact() {
      try {
        this.loadingContactsTable = true;
        await datasetsAPI.createContact(this.newContact);
        this.isCreatingContact = false;
        await this.GET_CONTACTS_LIST({
          usergroupId: this.currentUsergroup.id
        });
        this.loadingContactsTable = false;
      } catch (err) {
        console.error(err);
        this.loadingContactsTable = false;
      }
    },

    openContactRemoveModal(e) {
      this.contactToRemove = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteContact.title'),
        content: this.$t('modals.deleteContact.content', {
          contact: `${this.contactToRemove['Nom']}`,
        }),
        trigger: this.removeContact
      });
    },

    async removeContact() {
      this.loadingContactsTable = true;
      await datasetsAPI.removeContact(this.contactToRemove.id);
      await this.GET_CONTACTS_LIST({
        usergroupId: this.currentUsergroup.id
      });
      this.loadingContactsTable = false;
    },
  }
};
</script>

<style lang="less" scoped>

.container-fluid {
  margin: 1em;
  padding-bottom: 3em;
  height: fit-content;
  .row {
    height: fit-content;
  }
}

p {
  width: 100%;
  color: grey;
  font-style: italic;
  font-size: 0.7em;
  margin-bottom: 0.2em;
}

.add-contact {
  margin-left: 1rem;
}
.contact-form-footer {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  button.btn-primary {
    margin-left: 1em;
    font-size: 1.4em;
    border: 2px solid #9BD0FF;
    border-radius: 8px;
  }
}

.b-overlay-wrap {
  table {
    width: calc(100% - 1rem);
  }
}

</style>
